/* GENERAL VALUES */
* {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  /* border: 1px red solid; */
}

:root {
  /* Font sizes */
  --text-size-small-print: 0.64rem;
  --text-size-caption: 0.8rem;
  --text-size-body-text: 1rem;
  --text-size-h5: 1.25rem;
  --text-size-h4: 1.56rem;
  --text-size-h3: 1.95rem;
  --text-size-h2: 2.44rem;
  --text-size-h1: 3.05rem;
  --text-size-5xl: 3.81rem;

   /* Colors */
  --primary-color: #CC00CA;
  --secondary-color: #5CD8E6;
  --background-color: #FFFAFF;

  /* Fonts */
  font-family: 'Inter', sans-serif;
}

html {font-size: 16px; scroll-behavior: smooth;}

body {
  line-height: 1.65;
}

p {
  font-size: 1rem;
}

h1,
h2,
h3,
h4,
h5 {
  line-height: 1.15;
}

h1,
.text-size-h1 {
  font-weight: 900;
  margin-top: 0;
  font-size: 1.8em;
}

h2,
.text-size-h2 { font-size: 1.6em; font-weight: 500;}

h3,
.text-size-h3 { font-size: 1.42em; }

h4,
.text-size-h4 { font-size: 1.27em; }

h5,
.text-size-h5 { font-size: 1.13em; }

.text-caption { font-size: 0.89em; }

small,
.text-small {
  font-size: 0.79em;
}

a {
  color: black;
  text-decoration: none;
}

.bold {
  font-weight: 700;
}

.center-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.center-text {
  text-align: center;
}

.mandatory {
  color: red;
  font-weight: 900;
}

.hover {
  transition: all 0.3s ease;
  cursor: pointer;
}

.hover:hover {
  color: var(--primary-color);
}

/* APP */
.app--container {
  width: 100%;
}

.app--guadarropa {
  background-color: white;
}

.app--entradas {
  background-color: #fff1ff;
}
/* CTA BUTTON */
.CTA {
  font-size: 0.75rem;
  font-weight: 700;
  background-color: var(--primary-color);
  color: white;
  border-radius: 50px;
  height: 35px;
  width: 140px;
  box-shadow: rgba(0, 0, 0, 0.36) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  border: 0;
  text-align: center;
  cursor: pointer;
  transition: 0.3s ease;
}

.CTA:hover {
  background-color: white;
  border: 2px var(--primary-color) solid;
  color: var(--primary-color);
  box-shadow: none;
}

/* BLOG BUTTON */
.BLOG {
  font-size: 0.75rem;
  font-weight: 700;
  background-color: rgba(0, 0, 0, 0.2);
  border: 2px var(--primary-color) solid;
  color: white;
  border-radius: 50px;
  height: 35px;
  width: 140px;
  box-shadow: rgba(0, 0, 0, 0.36) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  text-align: center;
  cursor: pointer;
  transition: 0.4s ease;
}

.BLOG:hover {
  border: 2px var(--primary-color) solid;
  color: white;
  background-color: var(--primary-color);
  box-shadow: none;
}

/* NAVBAR */
.navbar--container {
  width: 100%;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s ease;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
}

.navbar--container:hover {
  box-shadow: none;
}

.navbar--content {
  display: flex;
  width: 100%;
  align-items: center;
  min-height: 10vh;
  justify-content:space-between;
  transition: 0.3s ease;
}

.logo {
  width: 160px;
  height: auto;
  cursor: pointer;
  padding-left: 20px;
}

.navbar--list {
  max-width: 100%;
  padding: 0;
  display: flex;
  flex: 1;
  align-items: center;
  list-style: none;
  gap: 10px;
  font-size: var(--text-size-caption);
}

.navbar--list > li > a{
  transition: all 0.3s ease;
}

.navbar--list > li > a:hover{
  color: var(--primary-color);
}

.navbar--list:last-child {
  padding-right: 10px;
}

.navbar--blog-button {
  display: none;
  font-size: 0.8rem;
}

.list-item {
  display: none;
}

/* HERO */
.hero--container {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("./images/hero-bg.webp");
  background-size: cover;
  background-position: center;
  display: flex;
  height: 90vh;
  justify-content: center;
  align-items: center;
  position: relative;
}

.hero--content {
  color: white;
  display: flex;
  justify-content: left;
  flex-direction: column;
  width: 80%;
}

.hero--content > h1 {
  font-weight: 800;
  font-size: 1.8rem;
  padding-bottom: 5px;
}

.hero--content > h2 {
  font-weight: 500;
  font-size: 1.1rem;
  padding-bottom: 10px;
}

.hero--CTA {
  display: flex;
  padding-bottom: 10px;
  gap: 10px;
}

.truster {
  font-size: 0.7rem;
  font-weight: 600;
  line-height: 0.9rem;
  display: flex;
  align-items: center;
  gap: 5px;
}

/* SECTION */
section {
  padding: 40px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section--content {
  display: flex;
  flex-direction: column;
  width: 80%;
  align-items: center;
  justify-content: center;
}

.section--content > img {
  width: 80%;
  align-self: center;
}

.section--text {
  display: flex;
  flex-direction: column;
}

.section--text > h2 {
  font-weight: 600;
  padding-bottom: 5px;
}

.section--text > p {
  padding-bottom: 20px;
  font-size: 0.9em;
}

/* FORM */

.form--content {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.form--contact-us {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form--contact-us > h3 {
  padding-bottom: 5px;
}

.form--form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.form--form > form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.input {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  padding-bottom: 10px;
  width: 280px;
}

.input > label {
  font-weight: 600;
}

form > .center-button {
  margin-top: 20px;
}

.form--option > label > input {
  margin-right: 5px;
}

.center-button > button {
  font-size: 0.75rem;
  font-weight: 700;
  border: 2px var(--primary-color) solid;
  background-color: white;
  color: var(--primary-color);
  border-radius: 50px;
  height: 35px;
  width: 140px;
  box-shadow: rgba(0, 0, 0, 0.36) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  text-align: center;
  cursor: pointer;
  transition: 0.4s ease;
}

.center-button > button:hover {
  background-color: var(--primary-color);
  color: white;
  box-shadow: none;
}

.input > input {
  padding: 10px;
  border-radius: 10px;
  border: 2px var(--primary-color) solid;
}

.form--form > .center-text {
  padding: 20px 0;
}

/* FOOTER */
footer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("./images/footer-bg.webp");
  color: white;
  background-size: cover;
  background-position: center;
  /* height: 60vh; */
  box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
  transition: all 0.4s ease;
  
}

footer:hover {
  box-shadow: none;
}

.footer--content {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 80%;
  padding-bottom: 0px;
  margin: 80px 0px;
}

.footer--content > h2{
  color: white;
  font-weight: 800;
  font-size: 2rem;
  transition: all 0.3s ease;
}

.footer--content > p {
  font-size: 1.1rem;
  margin-bottom: 40px;
}

.footer--info {
  display: flex;
  width: 100%;
  justify-content:space-between;
}

.footer--address > h5, .footer--contact > h5{
  font-size: 1rem;
}

.footer--address > p, .footer--contact > p{
  font-size: 0.7rem;
}

.footer--address {
  text-align: left;
}

.footer--contact {
  text-align: right;
}

.footer--contact > p > a {
  color: white;
  transition: all 0.3s ease;
}

.footer--contact > p > a:hover{
  color: var(--primary-color);
}

@media only screen and (min-width: 500px) {
  .navbar--content {
    width: 80%;
  }

  .navbar--blog-button {
    display: contents;
  }
}

/* Tablet */
@media only screen and (min-width: 700px) {
  /* CTA */
  .logo {
    width: 210px;
    padding: 0;
  }

  .CTA {
    font-size: 0.9rem;
    width: 160px;
  }

  .BLOG {
    font-size: 0.9rem;
    width: 160px;
  }

.center-button > button {
  font-size: 0.9rem;
  height: 45px;
  width: 150px;

}

  /* NAVBAR */
  .navbar--content {
    width: 80%;
    min-height: 8vh;
  }

  .navbar--list > .list-item {
    display:contents;
  }

  .navbar--list > li{
    font-size: 0.9rem;
  }

  /* HERO */
  .hero--content {
    width: 60%;
  }

  .hero--content > h1 {
    font-size: 1.8rem;
  }

  .hero--content > h2 {
    font-size: 1.2rem;
  }

  .truster {
    font-size: 0.85rem;
  }

  /* SECTION */
  section {
    padding: 80px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .section--content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60%;
  }

  /* FORM */
  .form--content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }

  .input {
    width: 350px;
  }

  /* FOOTER */
  .footer--content {
    margin: 80px 0px
  }

  .footer--address > h5, .footer--contact > h5{
    font-size: 1.1rem;
  }
  .footer--address > p, .footer--contact > p{
    font-size: 0.9rem;
  }
}

/* Laptop */
@media only screen and (min-width: 1000px) {
  .logo {
    width: 220px;
    height: auto;
  }

  /* CTA */
  .center-button {
    justify-content: left;
  }

  .CTA {
    font-size: 1rem;
    height: 40px;
    width: 200px;
  }

  /* BLOG */
  .BLOG {
    font-size: 1rem;
    height: 40px;
    width: 200px;
  }

  /* NAVBAR */
  .navbar--content {
    width: 70%;
    min-height:10vh ;
  }

  .navbar--list > .list-item {
    display:contents;
  }

  .navbar--list {
    gap: 20px;
  }

  .navbar--list > li{
    font-size: 1rem;
  }

  /* HERO */
  .hero--container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
    padding: 0;
  }
  
  .hero--content {
    padding: 0;
    display: flex;
    justify-content: left;
    width: 70%;

  }

  .hero--content > h1 {
    font-size: 3.2rem;
    padding-bottom: 10px;
  }

  .hero--content > h2 {
    font-size: 2rem;
    font-weight: 400;
    padding-bottom: 20px;
  }

  .truster-container {
    padding-top: 20px;
  }

  .truster {
    font-size: 0.85rem;
  }

  /* SECTION */
  section {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .section--content{
    max-width: 60%;
    font-size: 1.2rem;
    padding: 40px 100px;
    flex-direction: row;
    gap: 20px;
  }

  .section--content > img {
    width: 40%;
  }

  /* FOOTER */
  .footer--info {
    width: 90%;
  }

  .footer--address > h5, .footer--contact > h5{
    font-size: 1.3rem;
  }
  .footer--address > p, .footer--contact > p{
    font-size: 1.1rem;
  }

}

/* Monitor */
@media only screen and (min-width: 1440px) {

  .logo {
    width: 280px;
  }

  .hero--container {
    display: flex;
    justify-content: center;
  }

  .hero--content {
    display: flex;
    justify-content: left;
    width: 60%;
  }

  .navbar--list {
    gap: 40px;
  }

  .footer--info {
    width: 70%;
  }

  .input {
    width: 500px;
  }
}